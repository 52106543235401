import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <p>Something's gone wrong.</p>
          <a href="/"> Go to Home </a>
          <a onClick={() => window.location.reload()}> Refresh </a>
          <p>Error Message:</p>
          <code style={{ backgroundColor: "#EEE" }}>{this.state.error}</code>
        </div>
      );
    }

    return this.props.children;
  }
}
