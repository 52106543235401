import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  FiVideo,
  FiVideoOff,
  FiMic,
  FiMicOff,
  FiVolume2,
  FiVolumeX,
} from "react-icons/fi";

import { TooltipWrapper } from "../Tooltip";
import { useMedia } from "../../hooks/media";
import { VideoCapture } from "./VideoCapture";
import colors from "../../utils/colors";
import data from "../../data";
import GearIcon from "../../icons/Gear";
import UserAvatar from "../UserAvatar";

const StyledUserFooter = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;

  margin-bottom: 1px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  color: #fff;
  background: ${colors.grayDark};
  font-weight: 400;

  .avatar-wrapper {
    cursor: pointer;
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .username {
    font-size: 0.85em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tag {
    font-size: 0.73em;
    opacity: 0.4;
  }

  .buttons {
    display: flex;
    flex: 0 1 auto;
  }

  .vidfoot {
    margin-top: 20px;
    width: 240px;
  }
`;

const StyledIconButton = styled.button`
  margin: 0;
  padding: 0;
  outline: none;
  width: 32px;
  height: 32px;

  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: rgba(24, 25, 28, 0.3);
  }

  svg {
    width: 18px;
    height: 18px;
    color: #fff;
    opacity: 0.6;

    :hover {
      opacity: 0.8;
    }
  }
`;

const StyledFooter = styled.div`
  display: flex;
  margin: 10px 0;
`;

export default () => {
  const user = data.users[data.userId];
  const vidRef = useRef<HTMLVideoElement>();

  const {
    stream,
    videoEnabled,
    setVideoEnabled,
    micEnabled,
    setMicEnabled,
  } = useMedia();
  const [speakerOn, setSpeakerOn] = useState(true);

  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    console.log("mini", stream, vidRef);
    if (!stream) {
      return;
    }
    if (vidRef.current) {
      vidRef.current.srcObject = stream;
    }
  }, [stream, vidRef.current]);

  const toggleCam = () => {
    setVideoEnabled(!videoEnabled);
  };

  const toggleMic = () => {
    setMicEnabled(!micEnabled);
  };

  const toggleVolume = () => {
    setSpeakerOn(!speakerOn);
  };

  const toggleSettings = () => {
    setShowSettings(true);
  };

  if (showSettings) {
    return <VideoCapture onFinish={() => setShowSettings(false)} />;
  }

  return (
    <StyledUserFooter>
      {videoEnabled && (
        <video className="vidfoot" ref={vidRef} autoPlay muted></video>
      )}

      <StyledFooter>
        <UserAvatar
          className="avatar-wrapper"
          avatarUrl={user.avatar}
          fadeHover
        />

        <div className="content">
          <div className="username">{user.username}teste</div>
          <div className="tag">#{user.tag}</div>
        </div>

        <div className="buttons">
          <TooltipWrapper content="Unmute" direction="top">
            <StyledIconButton onClick={toggleCam}>
              {videoEnabled ? <FiVideo /> : <FiVideoOff />}
            </StyledIconButton>
          </TooltipWrapper>

          <TooltipWrapper content="Unmute" direction="top">
            <StyledIconButton onClick={toggleMic}>
              {micEnabled ? <FiMic /> : <FiMicOff />}
            </StyledIconButton>
          </TooltipWrapper>

          <TooltipWrapper content="Deafen" direction="top">
            <StyledIconButton onClick={toggleVolume}>
              {speakerOn ? <FiVolume2 /> : <FiVolumeX />}
            </StyledIconButton>
          </TooltipWrapper>

          <TooltipWrapper content="User Settings" direction="top">
            <StyledIconButton onClick={toggleSettings}>
              <GearIcon />
            </StyledIconButton>
          </TooltipWrapper>
        </div>
      </StyledFooter>
    </StyledUserFooter>
  );
};
