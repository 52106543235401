import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Spin } from "antd";

import { MediaDeviceListProvider } from "../../hooks/MediaDevice";
import { useLocalStorage } from "../../hooks/localStorage";
import { useUser } from "../../hooks/user";
import { useWebSocket, WebSocketContext } from "../../hooks/websocket";
import { uuidv4 } from "../../classes/util";
import { VideoCapture } from "./VideoCapture";
import Room from "./Room";
import { MediaProvider } from "../../hooks/media";

const StyledVC = styled.div`
  position: relative;
  height: 100%;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const VideoChat = ({ channelId, channelName }) => {
  const [rooms, setRooms] = useLocalStorage("rooms", []);
  const { issuer } = useUser();

  // TODO: session id for multi device
  // const sessionId = useState(() => uuidv4());

  return (
    <StyledVC>
      <MediaProvider>
        <Room
          userId={issuer}
          rooms={rooms}
          setRooms={setRooms}
          channelId={channelId}
          channelName={channelName}
        />
      </MediaProvider>
    </StyledVC>
  );
};
