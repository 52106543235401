import React, { useContext, useState, useEffect } from "react";
import { Magic, RPCErrorCode } from "magic-sdk";
import { useLocalStorage } from "../hooks/localStorage";

const magic = new Magic("pk_test_FAC27E9DA494D103");

export const UserContext = React.createContext({} as any);
export const useUser = () => useContext(UserContext);

const tokenLifespan = 30 * 24 * 3600; // 30 days

export const UserProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("user.did", null);
  const [issuer, setIssuer] = useLocalStorage("user.issuer", null);
  const [initialized, setInitialized] = useState(false);

  const refresh = async () => {
    // TODO: implement proper refresh
    if (token) {
      setInitialized(true);
      return;
    }

    const isLoggedIn = await magic.user.isLoggedIn();
    if (isLoggedIn) {
      console.log("magic says we're logged in, settting did");
      const did = await magic.user.getIdToken({ lifespan: tokenLifespan });
      setToken(did);
    } else {
      setToken(null);
    }

    if (!initialized) {
      setInitialized(true);
    }
  };

  useEffect(() => {
    refresh();

    // TODO: proper refresh after server implement auth logic
    // const interval = setInterval(refresh, (tokenLifespan / 2) * 1000);

    // return () => {
    //   clearInterval(interval);
    // };
  }, []);

  const login = async (email) => {
    try {
      const did = await magic.auth.loginWithMagicLink({ email });
      console.log("login", did);
      const { issuer } = await magic.user.getMetadata();
      setIssuer(issuer);
      setToken(did);
      setInitialized(true);
    } catch (err) {
      console.error("failed to login", err);
      if (err instanceof RPCError) {
        switch (err.code) {
          case RPCErrorCode.MagicLinkFailedVerification:
            console.error("MagicLinkFailedVerification", err);
          case RPCErrorCode.MagicLinkExpired:
            console.error("MagicLinkExpired", err);
          case RPCErrorCode.MagicLinkRateLimited:
            console.error("MagicLinkRateLimited", err);
          case RPCErrorCode.UserAlreadyLoggedIn:
            console.error("UserAlreadyLoggedIn", err);
            // Handle errors accordingly :)
            break;
        }
      }
    }
  };

  const logout = () => {
    (async () => {
      await magic.user.logout();
      setToken(null);
    })();
  };

  return (
    <UserContext.Provider value={{ token, issuer, initialized, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
