const config = {
  production: {
    COOKIE_DOMAIN: ".rtcdn.io",
    WS_HOST: "wss://ptv-server.rtcdn.io",
  },
  development: {
    COOKIE_DOMAIN: "localhost",
    WS_HOST: "ws://localhost:8080",
  },
  greengoblin: {
    COOKIE_DOMAIN: ".furqan.sh",
    WS_HOST: "wss://gg.furqan.sh:8080",
  },
};

const Config = config[process.env.NODE_ENV];

export default Config;
