import React, { createContext, useContext, useState, useEffect } from "react";
import Socket from "../classes/socket";

type ContextProps = {
  websocket: Socket;
  ready: boolean;
};

export const WebSocketContext = createContext<ContextProps>({
  websocket: null,
  ready: false,
});

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ token, children }) => {
  const [ready, setReady] = useState(false);
  const [websocket] = useState(new Socket());

  useEffect(() => {
    websocket.connect(token, () => setReady(true));

    return () => websocket.close();
  }, []);

  return (
    <WebSocketContext.Provider value={{ websocket, ready }}>
      {children}
    </WebSocketContext.Provider>
  );
};
