import React, { useState, useEffect, useReducer, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Select,
  Input,
  PageHeader,
  Button,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
} from "antd";
import styled from "styled-components";
const { Header, Content, Footer } = Layout;

import RTC from "../../classes/rtc";
import { useWebSocket } from "../../hooks/websocket";
import UserFooter from "./UserFooter";
import { useMedia } from "../../hooks/media";

const StyledR = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .vidgrid {
    width: 29%;
    height: 30%;
    margin: 2%;
  }
`;

const Video = ({ stream }) => {
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (videoRef.current && videoRef.current.srcObject !== stream) {
      videoRef.current.srcObject = stream;
    }
  }, [videoRef.current]);

  return (
    <video className="vidgrid" ref={videoRef} autoPlay playsInline muted />
  );
};

const Room = ({ user_id, room }) => {
  const history = useHistory();
  const [rtc, setRtc] = useState<RTC>(null);
  const [rtcReady, setRtcReady] = useState(false);
  const [roomUsers, setRoomUsers] = useState([]);
  const [publishing, setPublishing] = useState(false);
  const { websocket } = useWebSocket();
  const { stream } = useMedia();

  const room_id = room.room_id;

  const [state, dispatch] = useReducer(
    (state, { type, value }) => {
      switch (type) {
        case "ADD_STREAM":
          return {
            ...state,
            streams: [...state.streams, value],
          };
        case "REMOVE_STREAM":
          return {
            ...state,
            streams: state.streams.filter((x) => x !== value),
          };
        default:
          return state;
      }
    },
    { streams: [] }
  );

  function onStreamCallback(stream) {
    console.log("rtc.onStreamCallback", state.streams, stream);
    dispatch({ type: "ADD_STREAM", value: stream });
  }

  function onPublish() {
    setPublishing(true);
    (async () => {
      try {
        // await rtc.createLocalMediaStream({ audio: true, video: true });
        console.log(stream);
        await rtc.addMediaStream(stream);
      } catch (err) {
        console.error("failed to load room", err);
      }
    })();
  }

  function onScreenSharePublish() {
    setPublishing(true);
    (async () => {
      try {
        await rtc.createDesktopMediaStream({ audio: true, video: true });
      } catch (err) {
        console.error("failed to load room", err);
      }
    })();
  }

  function onHangup() {
    (async () => {
      try {
        if (!rtc) {
          console.warn("for some reason rtc is undefined");
          return;
        }
        dispatch({ type: "REMOVE_STREAM", value: stream });
        await rtc.removeLocalTracks();
      } catch (err) {
        console.error("failed to hangup", err);
      }
      setPublishing(false);
    })();
  }

  useEffect(() => {
    (async () => {
      console.log("doing initial connect");
      const RoomJoinResponse = await websocket.send("Room.Join", {
        user_id,
        room_id,
      });
      console.log("Room.Join response", RoomJoinResponse);

      websocket.subscribe("Room.Users", (m) => console.log("Room.Users", m));

      const rtc = new RTC(websocket, room_id);
      console.log({ rtc });

      websocket.subscribe("Room.Negotiate", (m) => {
        rtc.onServerNegotiate(m);
      });

      rtc.setOnStreamCallback((s) => onStreamCallback(s));
      await rtc.createPeerConnection();
      setRtcReady(true);
      setRtc(rtc);
    })();

    return () => {
      console.log("leaveRoom, close rtc");
      websocket.send("Room.Leave", {});
      if (rtc) {
        rtc.closeLocalMediaStream();
        rtc.closePeerConnection();
      }
    };
  }, [room]);

  return (
    <StyledR>
      <PageHeader
        // onBack={() => history.push("/")}
        // title={room ? room.name : ""}
        // subTitle={room ? room.room_id : ""}
        extra={
          publishing ? (
            <Button
              onClick={() => onHangup()}
              style={{ backgroundColor: "red", color: "white" }}
            >
              Hangup
            </Button>
          ) : (
            <Button
              onClick={() => onPublish()}
              style={{ backgroundColor: "green", color: "white" }}
              disabled={!rtcReady}
            >
              Join
            </Button>
          )
        }
      />
      <Row>
        {state.streams.map((stream, i) => (
          <Video stream={stream} key={i} />
        ))}
      </Row>
      <UserFooter stream={stream} />
    </StyledR>
  );
};

const RoomRouter = ({ userId, rooms, setRooms, channelId, channelName }) => {
  const room = rooms.filter((r) => r.room_id === channelId)[0];

  useEffect(() => {
    console.log(rooms, channelId, room);
    if (!room) {
      const name = "Room " + channelName;
      console.log(rooms);
      setRooms([...rooms, { room_id: channelId, name }]);
    }
  }, [channelId]);

  if (!room) {
    return null;
  }

  return <Room user_id={userId} room={room} />;
};

export default RoomRouter;
