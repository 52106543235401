import React from "react";
import styled from "styled-components";
import NewMessageForm from "./NewMessageForm";

const StyledNewMessageWrapper = styled.div`
  margin: 0 20px;
`;

const StyledContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const NewMessageWrapper = ({ channelName, isPrivate }) => (
  <StyledNewMessageWrapper>
    <StyledContainer>
      <NewMessageForm channelName={channelName} isPrivate={isPrivate} />
    </StyledContainer>
  </StyledNewMessageWrapper>
);

export default NewMessageWrapper;
