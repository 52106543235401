import Socket from "./socket";

const iceServers = [{ urls: ["stun:stun.rtcdn.io:3478"] }];

class RTC {
  websocket: Socket;
  localTracks: any[];
  remoteMediaStreams: {};
  pendingLocalMediaStreams: any[];
  localPeer: RTCPeerConnection;
  dataChannel: RTCDataChannel;
  onStreamCallback: any;
  roomId: any;

  constructor(websocket, roomId) {
    this.websocket = websocket;
    this.roomId = roomId;
    this.localTracks = [];
    this.pendingLocalMediaStreams = [];
    this.remoteMediaStreams = {};
  }

  async createPeerConnection() {
    this.localPeer = await new RTCPeerConnection({ iceServers: iceServers });
    this.localPeer.addEventListener("connectionstatechange", console.log);
    this.localPeer.addEventListener(
      "icecandidate",
      this.onIceCandidate.bind(this)
    );
    this.localPeer.addEventListener("icecandidateerror", console.error);
    this.localPeer.addEventListener("negotiationneeded", console.warn);
    this.localPeer.addEventListener(
      "datachannel",
      this.onDataChannel.bind(this)
    );
    this.localPeer.addEventListener("track", this.onTrack.bind(this));

    this.dataChannel = this.localPeer.createDataChannel("data");

    for (const pendingMediaStream of this.pendingLocalMediaStreams) {
      this.addMediaStream(pendingMediaStream);
    }

    this.pendingLocalMediaStreams = [];

    await this.negotiate();
  }

  async negotiate() {
    const offer = await this.createOffer();
    const ConnectRoomResponse = await this.websocket.send("Room.Connect", {
      offer: offer.sdp,
      room_id: this.roomId,
    });

    console.log("Room.Connect response", ConnectRoomResponse);
    return this.onAnswer(ConnectRoomResponse.answer);
  }

  onServerNegotiate({ room_id, user_id, num_tracks }) {
    // HACK: assume one audio + one video track
    for (let i = 0; i < num_tracks / 2; i++) {
      this.localPeer.addTransceiver("audio", { direction: "recvonly" });
      this.localPeer.addTransceiver("video", { direction: "recvonly" });
    }
    this.negotiate();
  }

  onTrack(trackEvent) {
    console.log("onTrack", trackEvent);
    const stream = trackEvent.streams[0];

    if (trackEvent.track.kind === "audio") {
      console.warn("skipping audio track");
      return;
    }

    // if (stream.id in this.remoteMediaStreams) {
    //   console.warn("stream.id already in remoteMediaStreams, early exit");
    //   return;
    // }

    // this.remoteMediaStreams[stream.id] = stream;

    if (this.onStreamCallback) {
      console.log("firing onStreamCallback", stream);
      this.onStreamCallback(stream);
    }
  }

  setOnStreamCallback(func) {
    this.onStreamCallback = func;
  }

  addMediaStream(mediaStream) {
    if (!this.localPeer) {
      console.error(
        "trying to add media w/o peer connection, adding to pending"
      );
      this.pendingLocalMediaStreams.push(mediaStream);
      return;
    }

    this.onStreamCallback(mediaStream);

    const videoTrack = mediaStream.getVideoTracks()[0];
    const audioTrack = mediaStream.getAudioTracks()[0];

    if (this.localPeer) {
      // console.log("localPeer.addTransceiver: send track to server");
      // this.localPeer.addTransceiver(videoTrack, { direction: "sendonly" });
      // this.localPeer.addTransceiver(audioTrack, { direction: "sendonly" });
      this.localTracks.push(this.localPeer.addTrack(videoTrack));
      this.localTracks.push(this.localPeer.addTrack(audioTrack));
      this.negotiate();
    }
  }

  removeLocalTracks() {
    for (const sender of this.localTracks) {
      this.localPeer.removeTrack(sender);
    }
  }

  async createLocalMediaStream({ audio, video }) {
    console.log(`creaing Local Media Stream, audio: ${audio}, video: ${video}`);
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: audio,
      video: video,
    });
    this.addMediaStream(localStream);

    return localStream;
  }

  async createDesktopMediaStream({ audio, video }) {
    console.log(`creaing Local Media Stream, audio: ${audio}, video: ${video}`);
    const localStream = await navigator.mediaDevices.getDisplayMedia({
      audio: audio,
      video: video,
    });
    this.addMediaStream(localStream);

    return localStream;
  }

  onDataChannel(event) {
    event.channel.onopen = (e) => {
      console.log("remoteDataChannel.open", e);
    };

    event.channel.onmessage = (e) => {
      console.log("remoteDataChannel.message", e.data);
    };

    //this.dataChannel.send("hi");
  }

  onIceCandidate(ice) {
    console.log("onIceCandidate", ice.candidate);
    if (ice.candidate) {
      /*      Socket.send("Room.IceCandidate", {*/
      //ice: ice.candidate,
      //room_id: this.roomId
      /*});*/
    }
  }

  async createOffer() {
    const offer = await this.localPeer.createOffer();

    await this.localPeer.setLocalDescription(new RTCSessionDescription(offer));

    return offer;
  }

  async onAnswer(answer) {
    console.log("onAnswer", answer);
    await this.localPeer.setRemoteDescription(
      new RTCSessionDescription(answer)
    );
  }

  closePeerConnection() {
    console.log("rtc.closePeerConnection()");

    if (this.localPeer) {
      this.localPeer.close();
      this.localPeer = null;
    }
  }
}

export default RTC;
