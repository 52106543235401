import React from "react";

const NotificationBellIcon = ({ className }) => (
  <svg
    name="NotificationBell"
    className={className}
    width="16"
    height="16"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M12.0062866,21 C13.0062866,21 13.7205723,20.5980469 14.0062866,19.9951172 L10.0062866,19.9951172 C10.2920009,20.5980469 11.0062866,21 12.0062866,21 L12.0062866,21 Z M18.9868164,17.0365601 L18.9868164,9 C18.9868164,6.36 16.6839496,4.02496338 13.9762573,4.02496338 C13.9762573,3.30496338 13.6322021,2.99511719 12.0031433,2.99511719 C10.3740845,2.99511719 10.0372925,3.30496338 10.0372925,4.02496338 C7.32960017,4.02496338 4.9954834,6.36 4.9954834,9 L4.9954834,17.0365601 L4.00628662,17.0365601 L4.00628662,18.9879761 L20,18.9879761 L20,17.0365601 L18.9868164,17.0365601 Z M17,17 L7,17 L7,9.3 C7,7.43 8.44444444,6 10.3333333,6 L13.6666667,6 C15.5555556,6 17,7.43 17,9.3 L17,17 L17,17 Z"
      />
      <rect width="24" height="24" />
    </g>
  </svg>
);

export default NotificationBellIcon;
