import { uuidv4 } from "./util";
import config from "./config";

const MESSAGE_RESPONSE_TIMEOUT = 10 * 1000;

class Socket {
  constructor() {
    this.connected = false;
    this.messageCallbacks = [];
    this.reqIdCallbacks = {};
    this.pendingSend = [];
  }

  connect(did, onConnect) {
    const url = `${config.WS_HOST}?did=${did}`;
    this.onConnect = onConnect;

    console.log("Socket.Connect to url: ", url);
    this.socket = new WebSocket(url);

    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onmessage = this.onMessage.bind(this);
    this.socket.onclose = this.onClose.bind(this);
    this.socket.onerror = this.onError.bind(this);
  }

  onMessage({ data }) {
    const message = JSON.parse(data);
    console.log("<<< ", message);
    const ackId = message.ack_id;
    if (ackId && ackId in this.reqIdCallbacks) {
      this.reqIdCallbacks[ackId](message);
      delete this.reqIdCallbacks[ackId];
      return;
    }

    this.messageCallbacks.forEach((callback) => {
      callback(message);
    });
  }

  onOpen(e) {
    this.connected = true;
    console.log("socket.on open", e);

    for (const { func, args, res, rejectTimeout } of this.pendingSend) {
      console.log("pendingSend", func, args);
      clearTimeout(rejectTimeout);
      (async () => {
        await this.send(func, args);
        res();
      })();
    }

    this.pendingSend = [];

    this.onConnect && this.onConnect();
  }

  onClose(e) {
    this.connected = false;
    console.log("onClose", e);

    //this.init();
  }

  onError(err) {
    console.log("onError", err);
  }

  addMessageHandler(callback) {
    this.messageCallbacks.push(callback);
  }

  subscribe(funcName, callback) {
    console.log("socket.subscribe: ", funcName);
    this.addMessageHandler((message) => {
      if (message.func === funcName) {
        callback(message.args);
      }
    });
  }

  async send(func, args) {
    const req_id = uuidv4();
    const requestBody = { req_id, func, args };
    console.log(">>> ", requestBody);

    if (!this.connected) {
      console.log("adding pendingSend", func, args);
      return new Promise((res, rej) => {
        const rejectTimeout = setTimeout(
          () => rej("pendingSend failed"),
          10000
        );
        this.pendingSend.push({ func, args, res, rejectTimeout });
      });
    }

    const respPromise = new Promise((res, rej) => {
      const timeout = setTimeout(() => {
        rej(`response timeout, req_id: ${req_id}, func: ${func}`);
      }, MESSAGE_RESPONSE_TIMEOUT);

      this.reqIdCallbacks[req_id] = (message) => {
        clearTimeout(timeout);
        if (message.error) {
          rej(message.error_reason);
          return;
        }
        res(message.args);
      };
    });

    this.socket.send(JSON.stringify(requestBody));

    return respPromise;
  }

  close() {
    this.socket.close();
  }
}

export default Socket;
