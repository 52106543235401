import React from "react";

import { Modal, Form, Input, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useUser } from "../hooks/user";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const Login = () => {
  const { login } = useUser();

  const onFinish = ({ email }) => {
    login(email);
  };

  return (
    <Modal title="Signup or Login" visible={true} footer={null}>
      <Form {...layout} name="email" onFinish={onFinish}>
        <Form.Item
          label="email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input
            type="email"
            placeholder="you@email.com"
            prefix={<MailOutlined />}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
