import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { App } from "./components/App";
import ErrorBoundary from "./components/ErrorBoundary";
import { UserProvider } from "./hooks/user";
import "./styles.scss";

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("app")
);
