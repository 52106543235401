import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "antd";
import { Select } from "antd";
import cx from "classnames";
import "./VideoCapture.scss";

const { Option } = Select;

import { useMediaDevice } from "../../hooks/MediaDevice";
import { useMedia } from "../../hooks/media";

const DeviceSelector = () => {};

export const VideoCapture = ({ onFinish }) => {
  const { stream, devices, videoEnabled, setVideoEnabled } = useMedia();
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (!stream) {
      return;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef.current]);

  const toggleVideo = () => {
    setVideoEnabled(!videoEnabled);
  };

  const handleOk = () => {
    onFinish();
  };

  const getDefaultVideoDevice = () => {
    const videoDevices =
      devices && devices.filter((d) => d.kind === "videoinput");

    if (!videoDevices || videoDevices.length === 0) {
      return null;
    }

    return videoDevices[0].deviceId;
  };

  if (devices.length === 0) {
    return null;
  }

  return (
    <div className={"VideoCapture"}>
      <div className="Content">
        <div>
          <h3> Prepare for awesomeness </h3>
        </div>
        <div>
          <span> Audio Input </span>
          <Select defaultValue="default" style={{ width: 300 }}>
            {devices
              .filter((d) => d.kind === "audioinput")
              .map((d) => (
                <Option key={d.deviceId} value={d.deviceId}>
                  {d.label}
                </Option>
              ))}
          </Select>
        </div>
        <div>
          <span> Video Input </span>
          <Select defaultValue={getDefaultVideoDevice()} style={{ width: 300 }}>
            {devices
              .filter((d) => d.kind === "videoinput")
              .map((d) => (
                <Option key={d.deviceId} value={d.deviceId}>
                  {d.label}
                </Option>
              ))}
          </Select>
        </div>

        <Button onClick={toggleVideo}> Toggle Video </Button>

        <video ref={videoRef} autoPlay muted />

        <Button onClick={handleOk}> OK </Button>
      </div>
    </div>
  );
};
