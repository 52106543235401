import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Spin } from "antd";

import { WebSocketProvider, useWebSocket } from "../hooks/websocket";
import ScrollbarStyles from "./ScrollbarStyles";
import GlobalStyle from "./GlobalStyle";
import Navbar from "./Navbar";
import Channels from "./Channels";
import Chat from "./Chat";
import { VideoChat } from "./VideoChat";
import MemberCardPopup from "./MemberCardPopup";
import TooltipsContainer from "./Tooltip/TooltipsContainer";
import { useUser } from "../hooks/user";
import colors from "../utils/colors";
import { Login } from "./Login";

import data from "../data";

const StyledApp = styled.div`
  display: flex;
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .app-content {
    flex: 1 1 auto;
  }
`;

const StyledLoggedOut = styled.div`
  display: flex;
  min-height: 100%;
  height: 100%;
  width: 100%;
  background: ${colors.grayLight};

  flex-direction: column;

  input {
    width: 300px;
  }
`;

export const LoggedOut = ({ children }) => {
  return (
    <StyledLoggedOut>
      <GlobalStyle />
      <ScrollbarStyles />

      {children}
    </StyledLoggedOut>
  );
};

export const Channel = () => {
  const {
    params: { channelId },
  } = useRouteMatch();

  const history = useHistory();
  const { token } = useUser();

  useEffect(() => {
    if (!channelId) {
      history.push(`/v/1111`);
    }
  }, [channelId]);

  const getSelectedGuild = () => {
    return channelId
      ? data.guilds.find((g) => g.id.toString() === channelId)
      : null;
  };

  const getGuildSelectedChannel = () => {
    const guild = getSelectedGuild();
    if (!guild) return null;

    const categoriesChannels = guild.categories.map((c) => c.channels);
    // Here we could use flat() to merge the channels but it is still experimental
    const channels = [].concat(...categoriesChannels);

    const id = guild.welcomeChannelId;
    return channels.find((channel) => channel.id === id);
  };

  const getSelectedChannelData = () => {
    const channel = getGuildSelectedChannel();
    return {
      id: channel.id,
      name: channel.name,
      messages: channel.messages || [],
    };
  };

  const handleChannelClick = (guildId) => {
    history.push(`/v/${guildId}`);
  };

  if (!channelId) {
    return null;
  }

  const selectedChatData = getSelectedChannelData();
  const selectedChannel = getSelectedGuild();

  return (
    <StyledApp>
      <GlobalStyle />
      <ScrollbarStyles />

      <Navbar
        onGuildClick={handleChannelClick}
        selectedGuildId={channelId && parseInt(channelId)}
      />
      <WebSocketProvider token={token}>
        <Chat
          className="app-content"
          isPrivate={false}
          guild={selectedChannel}
          channelName={selectedChannel.name}
          messages={selectedChatData.messages}
        />
      </WebSocketProvider>
    </StyledApp>
  );
};

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: ${colors.grayLight};
`;

export const App = () => {
  const { token, initialized } = useUser();
  if (!initialized) {
    return (
      <Centered>
        <Spin size="large" />
      </Centered>
    );
  }

  if (!token) {
    return <Login />;
  }

  return (
    <Switch>
      <Route exact path={["/", "/v/:channelId"]}>
        <Channel />
      </Route>
      <Route>
        <h1> 404 </h1>
      </Route>
    </Switch>
  );
};
