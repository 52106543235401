import React from "react";

const DiscordIcon = ({ className }) => (
  <svg
    name="Discord"
    className={className}
    width="16"
    height="16"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="discord-a"
        d="M19.3529746,6.6495336 C19.3529746,6.6495336 21.7924662,11.032553 21.7924662,16.4386778 C21.7924662,16.4386778 20.3621857,18.8861333 16.6198358,19 C16.6198358,19 16.0157664,18.2739306 15.5092969,17.6373278 C17.7346108,17.0185167 18.5728524,15.6375445 18.5728524,15.6375445 C17.8725385,16.0926723 17.2128913,16.4095334 16.6186497,16.6343862 C15.7732914,16.9825945 14.9499608,17.2169361 14.1559442,17.3623195 C12.5348692,17.6612195 11.0420637,17.5885278 9.76496094,17.353 Z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="currentColor" xlinkHref="#discord-a" />
    </g>
  </svg>
);

export default DiscordIcon;
