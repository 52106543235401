import React, { createContext, useContext, useState, useEffect } from "react";
import { useMediaDevice, MediaDeviceListProvider } from "./MediaDevice";

export const MediaContext = createContext<any>({});
export const useMedia = () => useContext(MediaContext);

export const MediaProviderInner = ({ children }) => {
  const devices = useMediaDevice();

  const [audioDevice, setAudioDevice] = useState(null);
  const [videoDevice, setVideoDevice] = useState(null);
  const [outputDevice, setOutputDevice] = useState(null);

  const [videoEnabled, setVideoEnabled] = useState(true);
  const [micEnabled, setMicEnabled] = useState(true);

  const [stream, setStream] = useState(null);

  useEffect(() => {
    stream &&
      stream.getTracks().forEach((track) => {
        track.stop();
      });

    navigator.mediaDevices
      .getUserMedia({
        audio: micEnabled && audioDevice,
        video: videoEnabled && videoDevice,
      })
      .then((stream) => {
        setStream(stream);
      });
  }, [micEnabled, audioDevice, videoEnabled, videoDevice]);

  return (
    <MediaContext.Provider
      value={{
        audioDevice,
        setAudioDevice,
        videoDevice,
        setVideoDevice,
        outputDevice,
        setOutputDevice,
        videoEnabled,
        setVideoEnabled,
        micEnabled,
        setMicEnabled,
        stream,
        setStream,
        devices,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export const MediaProvider = ({ children }) => (
  <MediaDeviceListProvider>
    <MediaProviderInner>{children}</MediaProviderInner>
  </MediaDeviceListProvider>
);
